nav {
    background-color:rgba(136, 136, 137, 0.3);
    width: calc(100% - 3rem);
    padding: 1.2rem 1.8rem;
    border-radius: 1.2rem;
    @media (min-width: 1024px) {
        border-radius: 2.4rem;
        padding: 1.8em 2.9em;
        width: calc(100% - 5rem);
    }
    @media (min-width: 1250px) {
        max-width: 1200px;
    }
    &.js-is-floating li a {
        color: #efefef;
    }
    font-size: 1rem;
}

.header-wrapper {
    margin-top: 13rem;
    max-width: 1000px;
    min-height: 10rem;
    @media (min-width: 1024px) {
        margin-top: 20rem;
    }
    &.home {
        max-width: 1200px;
        section {
            padding-top: 0rem;
            padding-left: 3rem;
            padding-right: 2rem;
            @media (min-width: 640px) {
                padding-left: 12rem;
                padding-right: 5rem;
            }
            @media (min-width: 1024px) {
                padding-top: 5rem;
                padding-left: 22rem;
                padding-right: 9rem;
            }
        }
    }
}

.api-docs .header-wrapper {
    max-width: 1200px;
    & > section {
        padding-left: 2.9rem;
    }
}

header {
    height: 27rem;
}

.h1 {
    font-size: 2rem;
    @media (min-width: 640px) {
        font-size: 2.8rem;
    }
    @media (min-width: 800px) {
        font-size: 3.2rem;
    }
    @media (min-width: 1024px) {
        font-size: 4.2rem;
    }
}

.internal-page {
    .h1 {
        padding: 0 0 0 1.5rem;
        font-size: 4rem;
        @media (min-width: 640px) {
            font-size: 4.8rem;
        }
        @media (min-width: 800px) {
            font-size: 5.2rem;
        }
        @media (min-width: 1024px) {
            font-size: 6.2rem;
            padding: 0 0 0 3.5rem;
        }
    }
}

.blob_a {
    top: 1.6rem;
    z-index: 1;
    svg {
        width: 33rem;
        height: 32rem;
    }
}

.blob_b {
    z-index: 2;
    svg {
        width: 36rem;
        height: 36rem;
    }
}

.blob_c {
    z-index: 0;
    top: 1.3rem;
    svg {
        width: 35rem;
        height: 32rem;
    }
}

.blob_d {
    left: 68.7rem;
    top: 1.6rem;
    svg {
        width: 6rem;
        height: 4.3rem;
    }
}

.blob_e {
    left: 77rem;
    top: 16rem;
    svg {
        width: 15rem;
        height: 10rem;
    }
}

.blob_f {
    left: 84rem;
    top: 1rem;
    svg {
        width: 33rem;
        height: 29rem;
    }
}

.home {
    .blob_a {
        left: 5rem;
    }
    .blob_b {
        left: 13.4rem;
    }
    .blob_c {
        left: 27rem;
    }
}

.internal-page {
    .blob_a {
        right: 19rem;
    }
    .blob_b {
        right: 5.4rem;
    }
    .blob_c {
        right: -5rem;
    }
}

.signup-button {
    font-size: 1.5em;
    background: linear-gradient(270deg, #0F68A9 0%, #58DF7C 100%);
}

.menu-icon {
    width: 2.4rem;
    height: 2.2rem;
}

.menu-icon span {
    display: block;
    position: absolute;
    height: .3rem;
    width: 100%;
    background: #c1c1c1;
    border-radius: .5rem;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.menu-icon span:nth-child(1) {
    top: 0;
}

.menu-icon span:nth-child(2),.menu-icon span:nth-child(3) {
    top: .9rem;
}

.menu-icon span:nth-child(4) {
    top: 1.8rem;
}

.menu-icon.open span:nth-child(1) {
    top: .9rem;
    width: 0%;
    left: 50%;
}

.menu-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.menu-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.menu-icon.open span:nth-child(4) {
    top: .9rem;
    width: 0%;
    left: 50%;
}

.user-display {
    padding-left: 27px;
    font-size: 14px;
    background: transparent url('/img/icons/icon-user.svg') no-repeat left 50%;
    @media (min-width: 1024px) {
        font-size: 15px;
    }
    @media (hover:hover) {
        &:hover {
            font-size: 15px;
            background-image: url('/img/icons/icon-user-white.svg');
        }
    }
}

.js-navbar {
    z-index: 100;
}