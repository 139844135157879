@font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Medium.woff2') format('woff2'), url('/fonts/Inter-Medium.woff') format("woff");
    font-weight: 700;
}

@font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-Light.woff2') format('woff2'), url('/fonts/Inter-Light.woff') format("woff");
    font-weight: 300;
}

html {
    font-size: 10px;
}

body {
    font-family: 'Inter Light', sans-serif;
    font-weight: 300;

    strong {
        font-family: 'Inter', sans-serif;
        font-weight: 700;
    }

    font-size: 1.7rem;
    color: #c1c1c1;
}

.text-heading {
    font-family: urw-din, sans-serif;
    font-weight: 500;
    font-style: normal;
}

.text-small {
    font-size: 1.4rem;
}

.text-medium {
    font-size: 1.6rem;
}
      
.text-reg {
    font-size: 1.7rem;
}

.text-large {
    font-size: 2rem;
}