body {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
}

a:focus,
button:focus {
    text-decoration: underline;
}

a:focus-visible,
button:focus-visible {
    outline: none;
}

.content {
    max-width: 1000px;
    margin-top: 3rem;

    @media (min-width: 1024px) {
        margin-top: 5rem;
    }

    .content-panel {
        h2 {
            font-size: 3.4rem;

            @media (min-width: 1024px) {
                font-size: 4rem;
            }
        }

        border-radius: 1.2rem;
        background-color: #2E3134;
        padding: 2.1rem 2.6rem;
        margin-top: 3rem;
        width: calc(100% - 3rem);
        margin-left: 1.5rem;
        margin-right: 1.5rem;

        @media (min-width: 1024px) {
            border-radius: 2.4rem;
            margin-top: 0;
            margin-left: 0;
            margin-right: 0;
            padding: 4.1rem 5.4rem;
            width: 100%;
        }
    }
}

.api-docs .content {
    max-width: 1200px;
    padding: 0 1.5rem;

    @media (min-width: 1230px) {
        padding: 0;
    }

    .frame-wrapper {
        overflow: hidden;
        border-bottom-left-radius: 1.2rem;
        border-bottom-right-radius: 1.2rem;

        @media (min-width: 1230px) {
            border-bottom-left-radius: 2.4rem;
            border-bottom-right-radius: 2.4rem;
        }
    }
}

.tt-intro .content-panel {
    font-size: 2rem;

    @media (min-width: 1024px) {
        width: auto;
        font-size: 3.1rem;
    }
}

.page-sign-up .content {
    max-width: 560px;

    .content-panel {
        width: auto;

        @media (min-width: 1024px) {
            padding: 3rem 2.5rem;
        }

        .col-6 {
            padding: 0 .5rem;
        }
    }
}

.page-two-factor .content {
    max-width: 560px;

    .content-panel {
        width: auto;

        @media (min-width: 1024px) {
            padding: 3rem 2.5rem;
        }
    }
}

.form-control {
    border-radius: .75rem;
    font-size: 1.7rem;
    line-height: 2.25rem;
}

textarea.form-control {
    min-height: 10rem;
}

.form-control[readonly] {
    @apply bg-brand-dark-grey;
}

.btn-primary {
    font-size: 15px;
    background: linear-gradient(270deg, #0F68A9 0%, #58DF7C 100%);
    border: 0;
    font-weight: 600;
    padding: 5px 12px;
    border-radius: 5px;
}

.focus-points {
    max-width: 1000px;
    margin-top: 4rem;

    @media (min-width: 1024px) {
        margin-top: 8rem;
    }

    h2 {
        font-size: 3.4rem;

        @media (min-width: 1024px) {
            font-size: 4rem;
        }
    }

    p {
        font-weight: 300;
        font-size: 1.7rem;

        @media (min-width: 1024px) {
            font-size: 2.2rem;
        }

        color: #c1c1c1;
    }

    & > div {
        margin-bottom: 4rem;
        background: transparent no-repeat;
        min-height: 18.7rem;

        @media (max-width: 479px) {
            padding-right: 115px;
        }
        
        @media (min-width: 480px) {
            padding-right: 150px;
        }

        @media (min-width: 1024px) {
            margin-bottom: 8rem;
            padding-right: 19.6rem;
        }
    }

    .secure {
        background-image: url('/img/icons/icon-fingerprint.svg');
        background-position: right 7.3rem;
        background-size: 8rem;
        
        @media (min-width: 1024px) {
            background-size: auto auto;
            background-position: calc(100% - 4rem) 8.8rem;
        }

        @media (max-width: 1023px) {
            background-position: right 50%;
        }
    }

    .caservice {
        background-image: url('/img/icons/icon-portcullis.svg');
        background-position: right 7.3rem;
        background-size: 109px;
        
        @media (max-width: 1023px) {
            background-position: right 50%;
            background-size: 94px;
        }

        @media (min-width: 1024px) {
            background-position: calc(100% - 4rem) 8.8rem;
        }
    }

    .secureqrcodes {
        background-image: url('/img/icons/icon-qr.svg');
        background-position: right 7.3rem;
        background-size: 94px;

        @media (max-width: 1023px) {
            background-position: right 50%;
        }

        @media (min-width: 1024px) {
            background-size: 104px;
            background-position: calc(100% - 4rem) 8.8rem;
        }
    }

    .flexible {
        background-image: url('/img/icons/icon-box.svg');
        background-position: right 6rem;
        background-size: 8rem;

        @media (min-width: 1024px) {
            background-size: auto auto;
            background-position: calc(100% - 2.4rem) 6.9rem;
        }

        @media (max-width: 1023px) {
            background-position: right 50%;
        }
    }

    .transparent {
        background-image: url('/img/icons/icon-link.svg');
        background-position: right 6rem;
        background-size: 8rem;

        @media (min-width: 1024px) {
            background-size: auto auto;
            background-position: calc(100% - 2.8rem) 7.4rem;
        }

        @media (max-width: 1023px) {
            background-position: right 50%;
        }
    }
}

footer {
    max-width: 1000px;
    padding: 8rem 1.5rem 2rem 1.5rem;
    text-align: center;
    font-weight: 300;
    font-size: 1.5rem;
    color: #c1c1c1;
}

nav li a,
nav li a:hover {
    font-weight: 300;
    font-size: 1.5rem;
    color: #fff;

    @media (min-width: 1024px) {
        color: #c1c1c1;
    }
}

.tab-list {
    font-weight: 300;
    font-size: 1.8rem;

    li>a {
        background-color: #212529;
        color: #fff;

        &[data-te-nav-active] {
            background-color: #828282;
            color: #fff;
        }
    }
}

.cookie-notice {
    display: none;
    position: fixed;
    bottom: 5vh;
    background-color: rgba(75, 74, 74, 0.8);
    z-index: 999;
    color: #fff;
    width: calc(100% - 5rem);
    border-radius: 1.2rem;

    h1 {
        font-size: 2.6rem;
        color: #f4f4f4
    }

    p {
        font-size: 1.6rem;
    }

    a {
        color: #fff;

        @media (min-width: 1024px) {
            &:hover {
                opacity: .6;
            }
        }
    }

    button {
        font-size: 1.5rem;
        background: linear-gradient(270deg, #0F68A9 0%, #58DF7C 100%);
    }

    @media (min-width: 1024px) {
        max-width: 760px;
    }
}

.privacy-policy {
    table thead th {
        padding: 15px;
        border-top: 1px solid #4f4f4f;
        border-right: 1px solid #4f4f4f;
        border-left: 1px solid #4f4f4f;
        font-size: 16px;
    }

    table td {
        border-top: 1px solid #4f4f4f;
        border-right: 1px solid #4f4f4f;
        border-bottom: 1px solid #4f4f4f;
        border-left: 1px solid #4f4f4f;
        position: relative;
        padding: 15px;
        font-weight: 300;
        font-size: 14px;
    }

    .lhpc {
        line-height: 145%;
        hyphens: none;
    }

    @media only screen and (max-device-width: 1024px) {

        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        table thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        table thead th {
            text-transform: none;
        }

        table tr {
            margin: 0 0 1rem 0;
        }

        table td {
            border: none;
            border-left: 1px solid #4f4f4f;
            border-bottom: 1px solid #4f4f4f;
            border-right: 1px solid #4f4f4f;
            position: relative;
            padding-left: 50%;
            min-height: 131px;
        }

        table tr td:first-of-type {
            border-top: 1px solid #4f4f4f;
        }

        table td:before {
            position: absolute;
            top: 0;
            left: 6px;
            width: 45%;
            padding: 15px;
            font-weight: 700;
        }

        table td:nth-of-type(1):before {
            content: "Purpose/Activity:";
        }

        table td:nth-of-type(2):before {
            content: "Type of data:";
        }

        table td:nth-of-type(3):before {
            content: "Lawful basis for processing including basis of legitimate interest:";
        }
    }
}

.gradient-button {
    background: linear-gradient(270deg, #0F68A9 0%, #58DF7C 100%);
}