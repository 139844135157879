@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~@fortawesome/fontawesome-free/css/all.css';

@import 'fonts';
@import 'header';
@import 'layout';

html {
    font-size: 10px;
}

.preload * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
}